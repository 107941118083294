import React from "react";

import { PageProps } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

import TypographyHeadline from "../components/1-atoms/TypographyHeadline/TypographyHeadline";
import RichText from "../components/2-molecules/RichText/RichText";

export default function Home(props: PageProps): JSX.Element {
  return (
    <>
      <GatsbySeo title="Impressum | Hautstark" nofollow noindex />

      <RichText>
        <TypographyHeadline>Impressum</TypographyHeadline>

        <p>
          <strong>Firmenname</strong>
          <br />
          Pierre Fabre Dermo-Kosmetik GmbH
          <br />
        </p>
        <p>
          <strong>Geschäftsführer</strong>
          <br />
          Hauke Voß, Pascal Voltzenlugel, Giuseppe Mele
        </p>
        <p>
          <strong>Adresse</strong>
          <br />
          Jechtinger Straße 13
          <br />
          D-79111 Freiburg
          <br />
          Telefon: +49 (0) 761 452 610
          <br />
          Fax: +49 (0) 761 452 61 677
        </p>
        <p>
          <strong>E-Mail:</strong> kosmetische-information_de@pierre-fabre.com
        </p>
        <p>
          <strong>Registergericht:</strong> Amtsgericht Freiburg
          <br />
          <strong>Registernummer:</strong> HRB 4902
        </p>
        <p>
          <strong>Umsatzsteuer-Identifikationsnummer:</strong> DE 135112787
        </p>
        <p>
          <strong>Inhaltlich Verantwortliche nach § 55 Abs. 2 RStV:</strong>
          <br />
          Christian Mies-Mäkelae
          <br />
          Jechtinger Straße 13
          <br />
          D-79111 Freiburg
        </p>
      </RichText>
    </>
  );
}
